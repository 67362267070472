export const ContactIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 13.3332C6 8.30485 6 5.7907 7.56209 4.2286C9.1242 2.6665 11.6383 2.6665 16.6667 2.6665H18.6667C23.6949 2.6665 26.2092 2.6665 27.7712 4.2286C29.3333 5.7907 29.3333 8.30485 29.3333 13.3332V18.6665C29.3333 23.6948 29.3333 26.209 27.7712 27.771C26.2092 29.3332 23.6949 29.3332 18.6667 29.3332H16.6667C11.6383 29.3332 9.1242 29.3332 7.56209 27.771C6 26.209 6 23.6948 6 18.6665V13.3332Z" stroke="currentColor" strokeWidth="2" />
                <path d="M6 8H2.66666M6 16H2.66666M6 24H2.66666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.3664 11.3233C20.3664 12.796 19.1725 13.99 17.6999 13.99C16.2272 13.99 15.0333 12.796 15.0333 11.3233C15.0333 9.8506 16.2272 8.65674 17.6999 8.65674C19.1725 8.65674 20.3664 9.8506 20.3664 11.3233Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.4264 20.9547C13.8376 18.7823 16.0785 17.9681 17.6999 17.9697C19.3213 17.9715 21.4965 18.7823 22.9076 20.9547C22.9989 21.0952 23.024 21.2681 22.9417 21.4141C22.6117 21.9993 21.5871 23.1605 20.8469 23.2393C19.9967 23.3297 17.7721 23.3424 17.7016 23.3428C17.6309 23.3424 15.3379 23.3297 14.4871 23.2393C13.7471 23.1605 12.7224 21.9993 12.3924 21.4141C12.31 21.2681 12.3352 21.0952 12.4264 20.9547Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const WebMobileIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.6667 22.9148C25.124 23.9998 22.9476 23.9998 18.5949 23.9998H13.2855C8.9328 23.9998 6.75645 23.9998 5.21373 22.9148C4.64297 22.5133 4.14653 22.0146 3.74689 21.4413C2.66666 19.8916 2.66666 17.7054 2.66666 13.3332C2.66666 8.96086 2.66666 6.77472 3.74689 5.22505C4.14653 4.65172 4.64297 4.15304 5.21373 3.7516C6.75645 2.6665 8.9328 2.6665 13.2855 2.6665" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 24V29.3333" stroke="currentColor" strokeWidth="2" />
                <path d="M10.6667 29.3335H21.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M24 2.6665C26.5141 2.6665 27.7712 2.6665 28.5523 3.44756C29.3333 4.2286 29.3333 5.48568 29.3333 7.99984V13.3332C29.3333 15.8473 29.3333 17.1044 28.5523 17.8854C27.7712 18.6665 26.5141 18.6665 24 18.6665H22.6667C20.1525 18.6665 18.8955 18.6665 18.1144 17.8854C17.3333 17.1044 17.3333 15.8473 17.3333 13.3332V7.99984C17.3333 5.48568 17.3333 4.2286 18.1144 3.44756C18.8955 2.6665 20.1525 2.6665 22.6667 2.6665H24Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M23.3333 15.3335H23.3423" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const MapLocationIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1716 18.3291C19.9468 18.5456 19.6463 18.6667 19.3335 18.6667C19.0207 18.6667 18.7201 18.5456 18.4953 18.3291C16.4363 16.3339 13.6768 14.1051 15.0225 10.8692C15.7501 9.11955 17.4967 8 19.3335 8C21.1703 8 22.9168 9.11955 23.6444 10.8692C24.9884 14.1009 22.2357 16.3407 20.1716 18.3291Z" stroke="currentColor" strokeWidth="2" />
                <path d="M19.3333 12.6665H19.3423" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.33334 16.0002C3.33334 10.029 3.33334 7.04348 5.18832 5.18848C7.04332 3.3335 10.0289 3.3335 16 3.3335C21.9711 3.3335 24.9567 3.3335 26.8117 5.18848C28.6667 7.04348 28.6667 10.029 28.6667 16.0002C28.6667 21.9712 28.6667 24.9568 26.8117 26.8119C24.9567 28.6668 21.9711 28.6668 16 28.6668C10.0289 28.6668 7.04332 28.6668 5.18832 26.8119C3.33334 24.9568 3.33334 21.9712 3.33334 16.0002Z" stroke="currentColor" strokeWidth="2" />
                <path d="M22.6667 28.0002L4 9.3335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.3333 18.6665L5.33333 26.6665" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const EmailUserIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33334 10L13.256 12.3192C15.5429 13.6713 16.4571 13.6713 18.744 12.3192L22.6667 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29.328 13.3335C29.3244 12.8445 29.3192 13.0271 29.3123 12.7022C29.2252 8.61356 29.1816 6.56925 27.6735 5.05489C26.1652 3.54053 24.0663 3.48777 19.8683 3.38227C17.2809 3.31724 14.7191 3.31724 12.1318 3.38225C7.93377 3.48776 5.83477 3.54051 4.3266 5.05487C2.81842 6.56924 2.77485 8.61353 2.68768 12.7021C2.65965 14.0168 2.65966 15.3236 2.68769 16.6383C2.77485 20.727 2.81844 22.7712 4.32661 24.2856C5.83477 25.7999 7.93377 25.8527 12.1318 25.9582C12.9424 25.9786 13.9943 25.9926 14.6667 26.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.7813 24.8643C19.9429 25.3556 17.7448 26.3591 19.0836 27.6147C19.7376 28.228 20.466 28.6667 21.3819 28.6667H26.6075C27.5232 28.6667 28.2516 28.228 28.9056 27.6147C30.2445 26.3591 28.0463 25.3556 27.2079 24.8643C25.2419 23.7119 22.7473 23.7119 20.7813 24.8643Z" stroke="currentColor" strokeWidth="2" />
                <path d="M26.6613 18.6667C26.6613 20.1395 25.4673 21.3333 23.9947 21.3333C22.5219 21.3333 21.328 20.1395 21.328 18.6667C21.328 17.1939 22.5219 16 23.9947 16C25.4673 16 26.6613 17.1939 26.6613 18.6667Z" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const FileUploadIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 15.9998V19.3921C6 23.7188 6 25.8821 7.18143 27.3474C7.42011 27.6434 7.68975 27.913 7.98577 28.1517C9.45108 29.3332 11.6144 29.3332 15.9411 29.3332C16.8819 29.3332 17.3521 29.3332 17.7829 29.1812C17.8725 29.1496 17.9603 29.1132 18.046 29.0722C18.4581 28.875 18.7907 28.5425 19.4559 27.8773L25.7712 21.562C26.542 20.7912 26.9273 20.4058 27.1304 19.9157C27.3333 19.4257 27.3333 18.8806 27.3333 17.7906V13.3332C27.3333 8.30485 27.3333 5.7907 25.7712 4.2286C24.2092 2.6665 21.6949 2.6665 16.6667 2.6665M18 28.6665V27.9998C18 24.2286 18 22.343 19.1716 21.1714C20.3432 19.9998 22.2288 19.9998 26 19.9998H26.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 6.6665C13.2135 5.85738 11.1204 2.6665 10 2.6665C8.87964 2.6665 6.78645 5.85738 6 6.6665M10 3.99984V13.3332" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const AudioCameraIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0002 8.00049C8.3727 8.00537 7.47199 8.0443 6.73186 8.35495C5.69515 8.79009 4.8509 9.59386 4.3577 10.6153C3.95513 11.449 3.88926 12.5172 3.7575 14.6534L3.55105 18.001C3.22341 23.3136 3.05958 25.9698 4.61847 27.6516C6.17735 29.3332 8.80359 29.3332 14.0561 29.3332H19.2777C24.5302 29.3332 27.1564 29.3332 28.7153 27.6516C30.2742 25.9698 30.1104 23.3136 29.7828 18.001L29.5762 14.6534C29.4445 12.5172 29.3786 11.449 28.9761 10.6153C28.4829 9.59386 27.6386 8.79009 26.602 8.35495C25.8618 8.0443 24.961 8.00537 23.3336 8.00049" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M23.3336 9.33317L22.1525 6.38041C21.6429 5.10645 21.1995 3.6613 19.8891 3.01257C19.1901 2.6665 18.3491 2.6665 16.6669 2.6665C14.9848 2.6665 14.1437 2.6665 13.4447 3.01257C12.1344 3.6613 11.6909 5.10645 11.1814 6.38041L10.0002 9.33317" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.2775 19.6633C22.4599 19.142 22.1851 18.5716 21.6637 18.3893C21.1424 18.2069 20.572 18.4817 20.3896 19.003L22.2775 19.6633ZM12.9442 19.003C12.7618 18.4817 12.1914 18.2069 11.6701 18.3893C11.1488 18.5716 10.874 19.142 11.0563 19.6633L12.9442 19.003ZM15.6669 24.6665C15.6669 25.2188 16.1147 25.6665 16.6669 25.6665C17.2192 25.6665 17.6669 25.2188 17.6669 24.6665H15.6669ZM18.0003 14.9998V17.6665H20.0003V14.9998H18.0003ZM15.3336 17.6665V14.9998H13.3336V17.6665H15.3336ZM16.6669 18.9998C15.9305 18.9998 15.3336 18.4029 15.3336 17.6665H13.3336C13.3336 19.5074 14.826 20.9998 16.6669 20.9998V18.9998ZM18.0003 17.6665C18.0003 18.4029 17.4033 18.9998 16.6669 18.9998V20.9998C18.5079 20.9998 20.0003 19.5074 20.0003 17.6665H18.0003ZM16.6669 13.6665C17.4033 13.6665 18.0003 14.2634 18.0003 14.9998H20.0003C20.0003 13.1589 18.5079 11.6665 16.6669 11.6665V13.6665ZM16.6669 11.6665C14.826 11.6665 13.3336 13.1589 13.3336 14.9998H15.3336C15.3336 14.2634 15.9305 13.6665 16.6669 13.6665V11.6665ZM20.3896 19.003C19.8445 20.5616 18.3779 21.6665 16.6669 21.6665V23.6665C19.2649 23.6665 21.4641 21.9888 22.2775 19.6633L20.3896 19.003ZM16.6669 21.6665C14.956 21.6665 13.4893 20.5616 12.9442 19.003L11.0563 19.6633C11.8697 21.9888 14.0689 23.6665 16.6669 23.6665V21.6665ZM15.6669 22.6665V24.6665H17.6669V22.6665H15.6669Z" fill="currentColor" />
            </svg>
        </>
    )
}

export const WhatsAppIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6666 29.3332C24.0304 29.3332 30 23.3636 30 15.9998C30 8.63604 24.0304 2.6665 16.6666 2.6665C9.30285 2.6665 3.33331 8.63604 3.33331 15.9998C3.33331 17.8384 3.70541 19.59 4.37841 21.1834C4.75035 22.064 4.93633 22.5044 4.95935 22.8372C4.98238 23.17 4.88443 23.536 4.68854 24.2681L3.33331 29.3332L8.39834 27.978C9.13049 27.7821 9.49657 27.6841 9.82934 27.7072C10.1621 27.7301 10.6024 27.9161 11.4831 28.2881C13.0766 28.961 14.8281 29.3332 16.6666 29.3332Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
                <path d="M12.1175 16.5032L13.2788 15.061C13.7682 14.4531 14.3732 13.8872 14.4207 13.0778C14.4325 12.8734 14.2888 11.9556 14.0011 10.12C13.888 9.39858 13.2145 9.3335 12.6311 9.3335C11.8709 9.3335 11.4907 9.3335 11.1133 9.50591C10.6362 9.72383 10.1464 10.3366 10.0389 10.8499C9.95385 11.2561 10.0171 11.536 10.1435 12.0957C10.6803 14.4732 11.9397 16.8212 13.8926 18.7742C15.8456 20.7271 18.1936 21.9866 20.5711 22.5234C21.1308 22.6498 21.4107 22.713 21.8169 22.6279C22.3303 22.5204 22.9429 22.0307 23.1609 21.5535C23.3333 21.176 23.3333 20.796 23.3333 20.0358C23.3333 19.4523 23.2683 18.7788 22.5468 18.6658C20.7112 18.378 19.7935 18.2343 19.5889 18.2462C18.7796 18.2936 18.2137 18.8986 17.6059 19.388L16.1636 20.5492" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const EmailShieldIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.83334 10L13.756 12.3192C16.0429 13.6713 16.9571 13.6713 19.244 12.3192L23.1667 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.1667 26.0002C17.1667 26.0002 13.9255 25.9839 12.6318 25.9514C8.43376 25.8459 6.33476 25.7932 4.8266 24.2794C3.31843 22.7654 3.27484 20.7218 3.18768 16.6343C3.15966 15.32 3.15964 14.0136 3.18767 12.6993C3.27484 8.61196 3.31842 6.56827 4.82659 5.05436C6.33476 3.54044 8.43376 3.48771 12.6318 3.38224C15.2191 3.31724 17.7809 3.31724 20.3683 3.38226C24.5663 3.48774 26.6652 3.54047 28.1735 5.05438C29.6816 6.56828 29.7252 8.61198 29.8123 12.6994C29.8263 13.3564 29.8333 14.6783 29.8333 15.3335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29.8333 22.6667V19.3333C27.1667 19.3333 25.1667 18 25.1667 18C25.1667 18 23.1667 19.3333 20.5 19.3333V22.6667C20.5 27.3333 25.1667 28.6667 25.1667 28.6667C25.1667 28.6667 29.8333 27.3333 29.8333 22.6667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const BriefcaseDollarIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.00006 18.6667C3.00006 13.9912 3.00006 11.6534 4.21067 10.0799C4.4323 9.79184 4.67991 9.52772 4.94997 9.29132C6.4251 8 8.61676 8 13.0001 8H19.6667C24.0501 8 26.2417 8 27.7169 9.29132C27.9869 9.52772 28.2345 9.79184 28.4561 10.0799C29.6667 11.6534 29.6667 13.9912 29.6667 18.6667C29.6667 23.3421 29.6667 25.68 28.4561 27.2535C28.2345 27.5415 27.9869 27.8056 27.7169 28.042C26.2417 29.3333 24.0501 29.3333 19.6667 29.3333H13.0001C8.61676 29.3333 6.4251 29.3333 4.94997 28.042C4.67991 27.8056 4.4323 27.5415 4.21067 27.2535C3.00006 25.68 3.00006 23.3421 3.00006 18.6667Z" stroke="currentColor" strokeWidth="2" />
                <path d="M21.6667 7.99984C21.6667 5.48568 21.6667 4.2286 20.8857 3.44756C20.1046 2.6665 18.8475 2.6665 16.3334 2.6665C13.8193 2.6665 12.5622 2.6665 11.7811 3.44756C11.0001 4.2286 11.0001 5.48568 11.0001 7.99984" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.3334 14.6668C14.8606 14.6668 13.6667 15.5623 13.6667 16.6668C13.6667 17.7714 14.8606 18.6668 16.3334 18.6668C17.8062 18.6668 19 19.5623 19 20.6668C19 21.7714 17.8062 22.6668 16.3334 22.6668M16.3334 14.6668C17.4944 14.6668 18.4822 15.2234 18.8483 16.0002M16.3334 14.6668V13.3335M16.3334 22.6668C15.1723 22.6668 14.1846 22.1103 13.8184 21.3335M16.3334 22.6668V24.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M8.33339 16H3.00006" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M29.6667 16H24.3334" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </>
    )
}

export const UserGroupIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.1549 26.6665H25.8085C27.3415 26.6665 28.561 25.968 29.6559 24.9913C32.4374 22.51 25.8989 19.9998 23.6667 19.9998M21.0001 6.7582C21.3029 6.69814 21.6173 6.6665 21.9398 6.6665C24.3663 6.6665 26.3334 8.45737 26.3334 10.6665C26.3334 12.8756 24.3663 14.6665 21.9398 14.6665C21.6173 14.6665 21.3029 14.6349 21.0001 14.5748" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M6.30846 21.4816C4.7365 22.324 0.614893 24.0441 3.12522 26.1965C4.3515 27.248 5.71726 28 7.43434 28H17.2324C18.9495 28 20.3152 27.248 21.5415 26.1965C24.0519 24.0441 19.9303 22.324 18.3583 21.4816C14.672 19.5061 9.9947 19.5061 6.30846 21.4816Z" stroke="currentColor" strokeWidth="2" />
                <path d="M17.6667 9.99984C17.6667 12.9454 15.2789 15.3332 12.3334 15.3332C9.38787 15.3332 7.00006 12.9454 7.00006 9.99984C7.00006 7.05432 9.38787 4.6665 12.3334 4.6665C15.2789 4.6665 17.6667 7.05432 17.6667 9.99984Z" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const PaperEditIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.667 14.6665V13.3332C26.667 8.30485 26.667 5.7907 25.1048 4.2286C23.5427 2.6665 21.0286 2.6665 16.0003 2.6665H14.6671C9.63881 2.6665 7.12466 2.6665 5.56257 4.22858C4.00047 5.79066 4.00045 8.3048 4.00041 13.3331L4.00037 18.6665C4.00031 23.6948 4.0003 26.2089 5.56234 27.771C7.12443 29.333 9.63867 29.3332 14.667 29.3332" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.0004 9.3335H20.667M10.0004 16.0002H20.667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M18.0003 27.7689V29.3332H19.5648C20.1107 29.3332 20.3836 29.3332 20.629 29.2316C20.8744 29.1298 21.0674 28.9369 21.4534 28.551L27.8848 22.119C28.2488 21.755 28.4308 21.573 28.5282 21.3768C28.7134 21.0032 28.7134 20.5646 28.5282 20.191C28.4308 19.9946 28.2488 19.8126 27.8848 19.4486C27.5207 19.0846 27.3387 18.9026 27.1423 18.8053C26.7687 18.6202 26.33 18.6202 25.9564 18.8053C25.7602 18.9026 25.578 19.0846 25.214 19.4486L18.7826 25.8806C18.3966 26.2665 18.2036 26.4594 18.102 26.7048C18.0003 26.9502 18.0003 27.223 18.0003 27.7689Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            </svg>
        </>
    )
}