import React, { useContext } from "react";
import { LogoMarkPrimary, LogoMarkXlFinancial, LogoWhiteFull } from "../../assets/images/index";
import { NavLink } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiAccountBoxOutline, mdiBriefcaseCheckOutline, mdiCalendarCheckOutline, mdiCalendarMultiple, mdiChartTimelineVariantShimmer, mdiCurrencyUsd, mdiDomain, mdiMenu, mdiNoteTextOutline, mdiViewDashboardOutline } from "@mdi/js";
import { ThemeContext } from "../../App";

function Sidebar(props) {
    const { currentTheme } = useContext(ThemeContext);

    const toggleSidebar = () => {
        document.body.classList.toggle('sidebar-collapsed');
        if (document.body.classList.contains('sidebar-collapsed')) {
            localStorage.setItem('sidebar-collapsed', true);
        } else {
            localStorage.setItem('sidebar-collapsed', false);
        }
    }
    const companyLogo = props?.userWorkspace?.logo || LogoWhiteFull

    return (
        <>
            <aside className="sidebar">
                <div className="logo-wrapper">

                    {<img src={companyLogo} alt={props?.userWorkspace?.name || 'SolvCRM'} className="logo" />}
                    {currentTheme?.name !== 'xl-financial' && <img src={LogoMarkPrimary} alt="SolvCRM" className="logo-collapsed" />}
                    {currentTheme?.name === 'xl-financial' && <img src={LogoMarkXlFinancial} alt="xl Financial" className="logo-collapsed" />}

                    <button type="button" className="icon-button light size-large" onClick={toggleSidebar}>
                        <Icon path={mdiMenu} color={'currentColor'} size={1} />
                    </button>
                </div>

                <ul className="main-nav">
                    <li>
                        <NavLink to={'/dashboard'} title="Dashboard">
                            <Icon path={mdiViewDashboardOutline} color={'currentColor'} size={1}></Icon>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    {props?.userAccessModule?.includes("lead") && props?.userPermissions?.includes("lead_list") && (
                        <li>
                            <NavLink to={'/leads'} title="Leads" className={window.location.pathname.includes('/lead-detail') ? 'active' : ''}>
                                <Icon path={mdiNoteTextOutline} color={'currentColor'} size={1}></Icon>
                                <span>Leads</span>
                            </NavLink>
                        </li>
                    )
                    }
                    {props?.userAccessModule?.includes("deal") && props?.userPermissions?.includes("deal_list") && (
                        <li>
                            <NavLink to={'/deals'} title="Deals" className={window.location.pathname.includes('/deal-detail') ? 'active' : ''}>
                                <Icon path={mdiBriefcaseCheckOutline} color={'currentColor'} size={1}></Icon>
                                <span>Deals</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("contact") && props?.userPermissions?.includes("contact_list") && (
                        <li>
                            <NavLink to={'/contacts'} title="Contacts" className={window.location.pathname.includes('/contact-detail') ? 'active' : ''}>
                                <Icon path={mdiAccountBoxOutline} color={'currentColor'} size={1}></Icon>
                                <span>Contacts</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("account") && props?.userPermissions?.includes("account_list") && (
                        <li>
                            <NavLink to={'/accounts'} title="Accounts" className={window.location.pathname.includes('/account-detail') ? 'active' : ''}>
                                <Icon path={mdiDomain} color={'currentColor'} size={1}></Icon>
                                <span>Accounts</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("quote") && props?.userPermissions?.includes("quote_list") && (
                        <li>
                            <NavLink to={'/quotes'} title="Quotes" className={
                                ['/create-quote', '/quote-detail/', '/quote-clone/'].some(path => window.location.pathname.includes(path))
                                    ? 'active'
                                    : ''
                            }>
                                <Icon path={mdiCurrencyUsd} color={'currentColor'} size={1}></Icon>
                                <span>Quotes</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("task") && props?.userPermissions?.includes("task_list") && (
                        <li>
                            <NavLink to={'/tasks'} title="Tasks" className={window.location.pathname.includes('/task-detail') ? 'active' : ''}>
                                <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1}></Icon>
                                <span>Tasks</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("event") && props?.userPermissions?.includes("event_list") && (
                        <li>
                            <NavLink to={'/events'} title="Events" className={window.location.pathname.includes('/event-detail') ? 'active' : ''}>
                                <Icon path={mdiCalendarMultiple} color={'currentColor'} size={1}></Icon>
                                <span>Events</span>
                            </NavLink>
                        </li>
                    )}
                    {props?.userAccessModule?.includes("report") && props?.userPermissions?.includes("report_list") && (
                        <li>
                            <NavLink to={'/reports'} title="Reports">
                                <Icon path={mdiChartTimelineVariantShimmer} color={'currentColor'} size={1}></Icon>
                                <span>Reports</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </aside >
        </>
    );
}

export default Sidebar;