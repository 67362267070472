import { favicon, internalServerErrorIcon, LogoMark, MobileDetected, pageNotFoundIcon } from "../assets/images";

const primaryMain = '#30c39e';
const primary25 = '#effbf8';
const primary50 = '#cef3ea';
const primary100 = '#adebdb';
const primary200 = '#8de2cd';
const primary300 = '#6cdabf';
const primary400 = '#4bd2b0';
const primary500 = '#30c39e';
const primary600 = '#29a385';
const primary700 = '#21836a';
const primary800 = '#186250';
const primary900 = '#104135';

const neutral25 = '#f5f5f5';
const neutral50 = '#e1e1e1';
const neutral100 = '#cccccc';
const neutral200 = '#b7b7b7';
const neutral300 = '#a3a3a3';
const neutral400 = '#8e8e8e';
const neutral500 = '#7a7a7a';
const neutral600 = '#666666';
const neutral700 = '#525252';
const neutral800 = '#3d3d3d';
const neutral900 = '#292929';

const warningMain = '#e6a71e';
const warning25 = '#fdf8ed';
const warning50 = '#fbf1da';
const warning100 = '#f7e2b6';
const warning200 = '#f3d491';
const warning300 = '#efc56c';
const warning400 = '#ebb747';
const warning500 = '#e6a71e';
const warning600 = '#ca9116';
const warning700 = '#a57712';
const warning800 = '#815c0e';
const warning900 = '#5c420a';

const successMain = '#29a385';
const success25 = '#effbf8';
const success50 = '#cef3ea';
const success100 = '#adebdb';
const success200 = '#8de2cd';
const success300 = '#6cdabf';
const success400 = '#4bd2b0';
const success500 = '#30c39e';
const success600 = '#29a385';
const success700 = '#21836a';
const success800 = '#186250';
const success900 = '#104135';

const errorMain = '#de3730';
const error25 = '#fff6f5';
const error50 = '#ffedea';
const error100 = '#ffdad6';
const error200 = '#ffb4ab';
const error300 = '#ff897d';
const error400 = '#ff5449';
const error500 = '#de3730';
const error600 = '#ba1a1a';
const error700 = '#93000a';
const error800 = '#690005';
const error900 = '#410002';

const infoMain = '#0a8ea0';
const info25 = '#ecfcfe';
const info50 = '#c6f5fb';
const info100 = '#a0eef8';
const info200 = '#79e7f6';
const info300 = '#53e0f3';
const info400 = '#2ddaf0';
const info500 = '#10cde5';
const info600 = '#0daabf';
const info700 = '#0b8899';
const info800 = '#086672';
const info900 = '#05444c';
const info950 = '#032226';

const systemWhite = '#ffffff';
const systemBlack = '#000000';
const systemTextPrimary = '#292929';
const systemTextSecondary = '#666666';
const systemTextTertiary = '#8e8e8e';
const systemBorder = '#c2c2c2';
const systemBorderLight = '#e1e1e1';

const fontFamily = 'proxima-nova, sans-serif';

const chartColor = {
    colorOne200: '#FFAB91',
    colorOne400: '#FF8A65',
    colorOne500: '#FF7043',
    colorTwo200: '#90CAF9',
    colorTwo400: '#64B5F6',
    colorTwo500: '#42A5F5',
    colorThree200: '#A5D6A7',
    colorThree400: '#66BB6A',
    colorThree500: '#4CAF50',
    colorFour400: '#EF5350',
    colorFive400: '#4DB6AC'
}

export const solvCrmTheme = {
    name: 'solvcrm',
    appName: 'SolvCRM',
    pageNotFoundImage: pageNotFoundIcon,
    internalServerErrorImage: internalServerErrorIcon,
    favicon: favicon,
    themeColor: '#30C39E',
    logoMark: LogoMark,
    mobileIllustration: MobileDetected,
    colors: {
        primary: {
            main: primaryMain,
            25: primary25,
            50: primary50,
            100: primary100,
            200: primary200,
            300: primary300,
            400: primary400,
            500: primary500,
            600: primary600,
            700: primary700,
            800: primary800,
            900: primary900
        },
        grey: {
            25: neutral25,
            50: neutral50,
            100: neutral100,
            200: neutral200,
            300: neutral300,
            400: neutral400,
            500: neutral500,
            600: neutral600,
            700: neutral700,
            800: neutral800,
            900: neutral900
        },
        warning: {
            main: warningMain,
            25: warning25,
            50: warning50,
            100: warning100,
            200: warning200,
            300: warning300,
            400: warning400,
            500: warning500,
            600: warning600,
            700: warning700,
            800: warning800,
            900: warning900
        },
        success: {
            main: successMain,
            25: success25,
            50: success50,
            100: success100,
            200: success200,
            300: success300,
            400: success400,
            500: success500,
            600: success600,
            700: success700,
            800: success800,
            900: success900
        },
        error: {
            main: errorMain,
            25: error25,
            50: error50,
            100: error100,
            200: error200,
            300: error300,
            400: error400,
            500: error500,
            600: error600,
            700: error700,
            800: error800,
            900: error900
        },
        info: {
            main: infoMain,
            25: info25,
            50: info50,
            100: info100,
            200: info200,
            300: info300,
            400: info400,
            500: info500,
            600: info600,
            700: info700,
            800: info800,
            900: info900,
            950: info950
        },
        system: {
            white: systemWhite,
            black: systemBlack,
            textPrimary: systemTextPrimary,
            textSecondary: systemTextSecondary,
            textTertiary: systemTextTertiary,
            border: systemBorder,
            borderLight: systemBorderLight,
            cardBackground: systemWhite
        }
    },
    colorVariables: {
        textBox: {
            hoverBorderColor: neutral500,
            focusBorderColor: primary600,
            errorBorderColor: error500,
            focusLabelColor: primary700,
            errorLabelColor: error500
        },
        button: {
            focusRing: primary700,

            primaryBackground: primary700,
            priamryTextColor: systemWhite,
            hoverPrimaryBackground: primary800,

            secondaryBackgroundColor: neutral50,
            hoverSecondaryBackgroundColor: neutral100,
            secondaryTextColor: neutral900,

            textTextColor: neutral900,
            hoverTextBackgroundColor: neutral25
        },
        menu: {
            menuItemHoverBackgroundColor: neutral25,
            backgroundColor: systemWhite,
            backgroundDark: systemWhite
        },
        checkboxRadio: {
            textColor: neutral200,
            focusRing: primary600,
            checkedTextColor: primary600,

            switchThumbColor: systemWhite,
            switchTrackColor: neutral100
        },
        progress: {
            color: primary600
        },
        alert: {
            errorBackgroundColor: error25,
            errorTextColor: error700,
            warningBackgroundColor: warning25,
            warningTextColor: warning800,
            successBackgroundColor: success25,
            successTextColor: success800,
            infoBackgroundColor: info25,
            infoTextColor: info800,
        },
        chip: {
            primaryBackgroundColor: primary100,
            primaryTextColor: primary900
        },
        dialog: {
            backgroundColor: systemWhite,
            backdropBackgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        linearProgress: {
            backgroundColor: primary100
        }
    },
    shadows: {
        shadow3: {
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.16)'
        }, shadow4: {
            boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.16)'
        }, shadow5: {
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1), 0 32px 64px rgba(0, 0, 0, 0.2)'
        }
    },
    fontFamily: fontFamily,
    chartColor: chartColor
}