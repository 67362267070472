import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../components/context/SnackbarContext';
import Cookies from "universal-cookie";
import useAxios from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { login, logout, permissions, workspaces, moduleAccess } from '../../redux/slices/userSlice';
import { useLoader } from "../../components/context/LoaderContext";
import { ThemeContext } from "../../App";
import { capitalize, getMainDomain, replaceSubdomain } from "../../utils/Utils";
import useLogoutSubmit from "../../hooks/useLogoutSubmit";

function SignIn(props) {
    const { currentTheme } = useContext(ThemeContext);
    const logoutSubmit = useLogoutSubmit();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = React.useState(false);
    const [displayMessage, setDisplayMessage] = useState();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const hostname = getMainDomain()

    const validationSchema = yup.object().shape({
        email: yup.string()
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Must be at most 50 characters')
            .required('Enter your email')
            .email('Please enter a valid email address'),
        password: yup.string()
            .required('Enter your password')
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            email: values.email,
            password: values.password,
        }
        showLoader('');
        setDisplayMessage()
        await axiosInstance.post("/api/login", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                if (response.data.data.user.email_verified_at == null) {
                    showSnackbar('Please verify your email.', 'warning')
                    navigate('/resend-email-password');
                } else {
                    cookies.set('auth_token', response.data.data.access_token, { domain: hostname || '' });
                    cookies.set('auth_name', response.data.data.user.name, { domain: hostname || '' });
                    cookies.set('auth_tenant', response.data.data.user.tenant, { domain: hostname || '' });
                    let subdomain = response.data.data.currentTenant.domain;
                    if (subdomain) {
                        // check if subdomain is app than no need to reload page
                        if (subdomain === 'app') {
                            navigate('/dashboard')
                        }

                        //check if subdomain is other than reload a page to get subdomain data
                        const redirectUrl = replaceSubdomain(window.location.href, subdomain)
                        if (redirectUrl) {
                            window.location = redirectUrl;
                        } else {
                            showSnackbar('Oops! Something went wrong. thare was an issue with redirect url.', 'error')
                            //dispatch(logout({ navigate }));
                            logoutSubmit();
                        }
                    } else {
                        if (response.data.data.user) {
                            dispatch(
                                login(response.data.data.user),
                            );
                        } else {
                            dispatch(
                                login([]),
                            );
                        }

                        if (response.data.data.currentTenant) {
                            dispatch(
                                workspaces(response.data.data.currentTenant),
                            );
                        } else {
                            dispatch(
                                workspaces([]),
                            );
                        }
                        if (response.data.data.permissionArray) {
                            dispatch(
                                permissions(response.data.data.permissionArray)
                            );
                        } else {
                            dispatch(
                                permissions([])
                            );
                        }

                        if (response.data.data.accessModuleLists) {
                            dispatch(moduleAccess(response.data.data.accessModuleLists))
                        } else {
                            dispatch(moduleAccess([]))
                        }
                        navigate('/dashboard')
                    }

                }
            }

        }).then(json => {
            showSnackbar(json.data.data.message, 'warning')
            navigate('/dashboard');
        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422) {
                    // Set field errors from Laravel validation errors
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else if (error.response.status === 400) {
                    setDisplayMessage(error.response.data.message)
                }
            }
        });
    }

    async function reSendVerificationEmail(email, onSubmitProps) {
        setDisplayMessage()
        const data = {
            email: email,
            type: 'signIn',
        }
        showLoader('Please wait we are sending verification code on email.')
        //await axiosInstance.post("/api/resend-email-verification", data).then(response => {
        await axiosInstance.post(`/api/resend-email-verification-code`, data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
            }
            if (onSubmitProps) {
                onSubmitProps.resetForm({ emai: '', password: '' });  // Reset form only if onSubmitProps exists
            }
            navigate('/verify-email/' + response?.data?.data?.hash + '/' + response?.data?.data?.email)
        }).then(json => {
            showSnackbar(json.data.data.message, 'warning')
        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (onSubmitProps) {
                    onSubmitProps.resetForm({ emai: '', password: '' });  // Reset form only if onSubmitProps exists
                }
                if (error.response.status === 422) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Sign In | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            {/* <Loader display={false} /> */}
            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>
                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">
                        <img src={currentTheme?.logoMark} alt={currentTheme?.appName} className="login-logo" />
                        <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Welcome Back!</Typography>
                        <Typography variant="body-large-semibold" className="login-subtitle" component={'p'}>Sign in to access your account and manage your leads effectively.</Typography>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                error_list: [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    //isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            label="Email"
                                            variant="filled"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.email && touched.email}
                                            helperText={(errors.email && touched.email) && errors.email}
                                        />

                                        <TextField
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            variant="filled"
                                            name="password"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {showPassword
                                                                ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                                : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.password && touched.password}
                                            helperText={(errors.password && touched.password) && errors.password}
                                        />

                                        <div className="forgot-password-link">
                                            <Link to="/forgot-password" className="primary-link">
                                                <Typography variant="label-large">Forgot Password?</Typography>
                                            </Link>
                                        </div>

                                        {
                                            displayMessage === 'Your email is not verified yet, Please verify it and try again.' &&
                                            <Alert severity="error">Please verify your email to proceed. <a href="-" className="text-underline" onClick={(e) => {
                                                e.preventDefault();
                                                reSendVerificationEmail(values.email)
                                            }}>
                                                <Typography variant="body-medium-semibold">Resend verification email</Typography>
                                            </a> </Alert>
                                        }
                                        <Button type="submit" className="btn-block">Sign In</Button>
                                    </form>
                                );
                            }}
                        </Formik>
                        <div className="login-signup">
                            <Typography variant="label-large">Don’t have an account?</Typography>
                            <Link to="/sign-up" className="primary-link"><Typography variant="label-large-bold">Sign Up</Typography></Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default SignIn;