import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Icon, LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LogoColorFull, LogoMarkXlFinancial } from "../../assets/images/index";
import { AudioCameraIcon, BriefcaseDollarIcon, ContactIcon, EmailShieldIcon, EmailUserIcon, FileUploadIcon, MapLocationIcon, PaperEditIcon, UserGroupIcon, WebMobileIcon, WhatsAppIcon } from "../../components/common/Icons";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { capitalize } from "../../utils/Utils";
import { ThemeContext } from "@emotion/react";
import { mdiArrowRight } from "@mdi/js";

function CreateTenantPanel(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { currentTheme } = useContext(ThemeContext);
    const pathname = window.location.pathname;
    const getArray = pathname.split('/');
    const id = getArray[2];
    const token = getArray[3];
    const [displayLoader, setDisplayLoader] = useState(true);

    useEffect(() => {
        async function fetchData() {
            verifyEmailData()
        }
        fetchData();
    }, [id]);


    async function verifyEmailData() {
        const data = {
            id: id,
            hash: token,
        };

        //showLoader('Please wait, we are creating your panel. This will only take a moment.');
        try {
            const response = await axiosInstance.get(`/api/verify-email/${id}/${token}`, data);
            if (response.status === 200) {
                setDisplayLoader(false)
                showSnackbar(response.data.message, 'success');
            }

        } catch (error) {
            console.log('error', error)
            if (error.response) {
                showSnackbar(error.response.data.message, 'warning');
                navigate('/sign-in');
            }
        }
    }

    const redirectToSign = () => {
        navigate('/sign-in');
    }


    return (
        <>
            <Helmet>
                <title>Verify Email | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <section className="welcome">
                <div className="primary-container">
                    {currentTheme?.name !== "xl-financial" && (<img src={LogoColorFull} alt="SolvCRM" className="logo" />)}
                    {currentTheme?.name === "xl-financial" && (<img src={LogoMarkXlFinancial} alt="xl Financial" className="logo" />)}

                    <div className="page-title">
                        <Typography variant="display-small-bold" component={'h1'}>Get Ready to Transform Your Workflow with <span className="text-brand">{currentTheme?.appName}</span></Typography>
                        <p>Congratulations on setting up your account! Your 14-day free trial starts today, giving you full access to all the features that make SolvCRM the perfect tool for managing your business.</p>
                    </div>

                    <div className="features">
                        <Typography variant="title-medium-large-semibold" className="title" component={'p'}>Discover What You Get with {currentTheme?.appName}’s 14-Day Trial</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <ul className="feature-list accent1">
                                    <li>
                                        <div className="icon-wrapper">
                                            <ContactIcon />
                                        </div>
                                        <p>Unlimited Contacts</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <WebMobileIcon />
                                        </div>
                                        <p>Web and Mobile App</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <MapLocationIcon />
                                        </div>
                                        <p>Geo tracking</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <EmailUserIcon />
                                        </div>
                                        <p>One-on-One email messages</p>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <ul className="feature-list accent2">
                                    <li>
                                        <div className="icon-wrapper">
                                            <FileUploadIcon />
                                        </div>
                                        <p>Unlimited Leads</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <AudioCameraIcon />
                                        </div>
                                        <p>Audio notes, Image capture</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <WhatsAppIcon />
                                        </div>
                                        <p>One-on-One Whatsapp Messages</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <EmailShieldIcon />
                                        </div>
                                        <p>Emails through SolvCRM.co domain</p>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <ul className="feature-list accent3">
                                    <li>
                                        <div className="icon-wrapper">
                                            <BriefcaseDollarIcon />
                                        </div>
                                        <p>Unlimited Deals</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <UserGroupIcon />
                                        </div>
                                        <p>Unlimited Users</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <PaperEditIcon />
                                        </div>
                                        <p>Create and Send Quotes</p>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        displayLoader ?

                            <div className="in-progress-block">
                                <LinearProgress className="progress" />
                                <Typography variant="title-medium-large-semibold" component={'p'}>Setting things up—please don’t refresh or close this page.</Typography>
                            </div>
                            :
                            <div className="action-block">
                                <Button size="large" onClick={() => redirectToSign()}>
                                    <span>
                                        <span>Let’s Sign In</span>
                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                    </span>
                                </Button>
                                <p className="text-secondary">Make the most of your free trial.</p>
                            </div>
                    }



                </div>
            </section>
        </>
    );
}

export default CreateTenantPanel;