import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { capitalize } from "../../utils/Utils";
import { Formik } from "formik";
import { ThemeContext } from "@emotion/react";
import * as yup from "yup";
import { LogoMark, LogoMarkXlFinancial } from "../../assets/images";

function VerifyEmail(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { currentTheme } = useContext(ThemeContext);
    const [timer, setTimer] = useState(0); // Timer state
    const [isResendVisible, setIsResendVisible] = useState(true); // State to toggle "Resend Code"

    const pathname = window.location.pathname;
    const getArray = pathname.split('/');
    const hash = getArray[2];
    const email = getArray[3];

    // Effect to handle countdown
    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setIsResendVisible(true); // Show "Resend Code" after timer ends
        }
        return () => clearInterval(interval);
    }, [timer]);



    const handleResendCode = async (e) => {
        if (e) {
            e.preventDefault();
        }

        // Logic to send OTP (e.g., API call) can be added here
        const data = {
            hash: hash,
            email: email,
            type: 'verificationEmail',
        };

        showLoader();
        try {
            const response = await axiosInstance.post(`/api/resend-email-verification-code`, data);
            if (response.status === 200) {
                hideLoader();
                setTimer(60); // Start the timer for 60 seconds
                setIsResendVisible(false); // Hide "Resend Code"
                showSnackbar(response.data.message, 'success');
            }

        } catch (error) {
            hideLoader();
            if (error.response) {
                showSnackbar(error.response.data.message, 'warning');
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        //onSubmitProps.setFieldError(key, errors[key]);
                        showSnackbar(errors[key], 'warning');
                    });
                    navigate('/sign-in');
                } else {
                    showSnackbar(error.response.data.message, 'error')
                    navigate('/sign-in');
                }
            }
        }

    };

    const validationSchema = yup.object().shape({
        verification_code: yup
            .string()
            .matches(/^[a-zA-Z0-9]{6}$/, 'Verification code must be exactly 6 digits')
            .required('Verification code is required'),
    })

    async function handleSubmit(values) {
        const data = {
            hash: hash,
            email: email,
            verification_code: values.verification_code,
        };

        showLoader();
        try {
            const response = await axiosInstance.post(`/api/verify-email-code`, data);
            if (response.status === 200) {
                hideLoader();
                showSnackbar(response.data.message, 'success');
                navigate('/welcome/' + response?.data?.data?.id + '/' + response?.data?.data?.hash)
            }

        } catch (error) {
            hideLoader();
            if (error.response) {
                console.log(error.response)
                showSnackbar(error.response.data.message, 'warning');
            }
        }
    }


    return (
        <>
            <Helmet>
                <title>Verify Your Email Address | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>

                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">
                        {currentTheme?.name !== "xl-financial" && (<img src={LogoMark} alt="SolvCRM" className="login-logo" />)}
                        {currentTheme?.name === "xl-financial" && (<img src={LogoMarkXlFinancial} alt="xl Financial" className="login-logo" />)}
                        <Typography variant="headline-medium-bold" className="login-title" component={"h2"}>Verify Your Email Address</Typography>
                        <Typography variant="body-large-semibold" className="login-subtitle" component={"p"}>We’ve sent a One-Time Password (OTP) to your email address:{" "} <strong>{email?.email || ''}</strong> Please enter the code below to verify your email and continue.</Typography>
                        <Formik
                            initialValues={{
                                verification_code: '',
                                error_list: [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    //isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            label="One Time Password"
                                            variant="filled"
                                            name="verification_code"
                                            value={values.verification_code}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.verification_code && touched.verification_code}
                                            helperText={(errors.verification_code && touched.verification_code) && errors.verification_code}
                                            inputProps={{
                                                maxLength: 6, // Limits the input to 6 characters
                                            }}
                                        />
                                        <Button type="submit" className="btn-block">Verify Email</Button>
                                    </form>
                                );
                            }}
                        </Formik>
                        <div className="login-signup">
                            <Typography variant="label-large">Didn’t receive the OTP?</Typography>
                            {!isResendVisible ? (
                                <Typography variant="label-large-bold" className="ml-4 text-brand">Resend after {timer}s</Typography>
                            ) : (
                                <a href="-" onClick={handleResendCode} className="primary-link"><Typography variant="label-large-bold">Resend Code</Typography></a>
                            )}
                        </div>

                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default VerifyEmail;